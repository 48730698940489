import { useEffect } from "react";
import { navigate } from "gatsby";

const RedirectTemplate = (props: any) => {
  useEffect(() => {
    navigate(props.pageContext.to);
  }, []);

  return null;
};

export default RedirectTemplate;
